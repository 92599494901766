/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:d1ad6bae-1177-4d04-a88a-d62131a1e6d2",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_9tS7A8K9t",
    "aws_user_pools_web_client_id": "lano9mvoua312uj52odbi447m",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://lu7b5lnrlfa3jp6c5zetn6iuq4.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cloud_logic_custom": [
        {
            "name": "enterpriseapptypeformwebhook-ssmain",
            "endpoint": "https://5bnswrnys8.execute-api.us-east-1.amazonaws.com/ssmain",
            "region": "us-east-1"
        },
        {
            "name": "enterpriseappconfigureuser-ssmain",
            "endpoint": "https://8jcu1dqfnh.execute-api.us-east-1.amazonaws.com/ssmain",
            "region": "us-east-1"
        },
        {
            "name": "enterpriseappinviteuser",
            "endpoint": "https://c8ux8l3gya.execute-api.us-east-1.amazonaws.com/ssmain",
            "region": "us-east-1"
        },
        {
            "name": "enterpriseappgetinvitebyid",
            "endpoint": "https://5dvb001awf.execute-api.us-east-1.amazonaws.com/ssmain",
            "region": "us-east-1"
        },
        {
            "name": "enterpriseappevents",
            "endpoint": "https://bm8j8mcoi1.execute-api.us-east-1.amazonaws.com/ssmain",
            "region": "us-east-1"
        },
        {
            "name": "enterpriseappchargebeewrapper",
            "endpoint": "https://ilvpzv72tk.execute-api.us-east-1.amazonaws.com/ssmain",
            "region": "us-east-1"
        },
        {
            "name": "enterpriseappchargebeewebhook",
            "endpoint": "https://91v7jyyj48.execute-api.us-east-1.amazonaws.com/ssmain",
            "region": "us-east-1"
        },
        {
            "name": "enterpriseappmetrics",
            "endpoint": "https://vpm5adzo3a.execute-api.us-east-1.amazonaws.com/ssmain",
            "region": "us-east-1"
        },
        {
            "name": "enterpriseappscormwrapper",
            "endpoint": "https://g9zsgiisyi.execute-api.us-east-1.amazonaws.com/ssmain",
            "region": "us-east-1"
        },
        {
            "name": "enterpriseappscormwebhook",
            "endpoint": "https://680h8acrfe.execute-api.us-east-1.amazonaws.com/ssmain",
            "region": "us-east-1"
        }
    ],
    "aws_user_files_s3_bucket": "enterpriseappstoragebucket94453-ssmain",
    "aws_user_files_s3_bucket_region": "us-east-1",
    "aws_mobile_analytics_app_id": "2956a842031a4272bdaf202ac9570481",
    "aws_mobile_analytics_app_region": "us-east-1"
};


export default awsmobile;
