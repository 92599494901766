// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';

const ModelSortDirection = {
  "ASC": "ASC",
  "DESC": "DESC"
};

const ModelAttributeTypes = {
  "BINARY": "binary",
  "BINARY_SET": "binarySet",
  "BOOL": "bool",
  "LIST": "list",
  "MAP": "map",
  "NUMBER": "number",
  "NUMBER_SET": "numberSet",
  "STRING": "string",
  "STRING_SET": "stringSet",
  "NULL": "_null"
};

const Status = {
  "UNCONFIRMED": "UNCONFIRMED",
  "CONFIRMED": "CONFIRMED",
  "ARCHIVED": "ARCHIVED",
  "COMPROMISED": "COMPROMISED",
  "UNKNOWN": "UNKNOWN",
  "RESET_REQUIRED": "RESET_REQUIRED",
  "FORCE_CHANGE_PASSWORD": "FORCE_CHANGE_PASSWORD"
};

const Role = {
  "OWNER": "OWNER",
  "ADMIN": "ADMIN",
  "MANAGER": "MANAGER"
};

const InvitationStatus = {
  "REQUESTED": "REQUESTED",
  "ACCEPTED": "ACCEPTED",
  "REVOKED": "REVOKED"
};

const EnterpriseMembershipStatus = {
  "IN_TRIAL": "IN_TRIAL",
  "ACTIVE": "ACTIVE",
  "NON_RENEWING": "NON_RENEWING",
  "PAUSED": "PAUSED",
  "CANCELLED": "CANCELLED",
  "DISABLED": "DISABLED"
};

const SubscriptionProvider = {
  "CHARGEBEE": "CHARGEBEE"
};

const EnterpriseSubscriptionStatus = {
  "ACTIVE": "ACTIVE",
  "NON_RENEWING": "NON_RENEWING",
  "PAUSED": "PAUSED",
  "CANCELLED": "CANCELLED"
};

const FeatureSlug = {
  "USER_LICENCES": "USER_LICENCES",
  "MONTHLY_LESSONS": "MONTHLY_LESSONS"
};

const FeatureType = {
  "SWITCH": "SWITCH",
  "CUSTOM": "CUSTOM",
  "QUANTITY": "QUANTITY",
  "RANGE": "RANGE"
};

const SubscriptionItemType = {
  "PLAN": "PLAN"
};

const BillingPeriodUnit = {
  "DAY": "DAY",
  "WEEK": "WEEK",
  "MONTH": "MONTH",
  "YEAR": "YEAR"
};

const AssignmentSource = {
  "TYPEFORM": "TYPEFORM"
};

const CourseRegistrationStatus = {
  "ASSIGNED": "ASSIGNED",
  "REQUESTED": "REQUESTED",
  "APPROVED": "APPROVED",
  "COMPLETED": "COMPLETED"
};

const CourseType = {
  "DIPLOMA_COURSE": "DIPLOMA_COURSE",
  "CERTIFIED_COURSE": "CERTIFIED_COURSE",
  "ACCELERATOR_COURSE": "ACCELERATOR_COURSE"
};

const CourseLearningStandard = {
  "CMI5": "CMI5"
};

const CourseAccessType = {
  "PUBLIC": "PUBLIC",
  "PRIVATE": "PRIVATE"
};

const CourseStatus = {
  "PROCESSING": "PROCESSING",
  "AVAILABLE": "AVAILABLE",
  "UPLOAD_FAILED": "UPLOAD_FAILED"
};

const CourseUploadType = {
  "SINGLE_VIDEO_DIRECT": "SINGLE_VIDEO_DIRECT"
};

const ResourceType = {
  "DOCUMENT": "DOCUMENT",
  "VIDEO": "VIDEO"
};

const DocumentSource = {
  "LINK": "LINK"
};

const VideoSource = {
  "VIMEO": "VIMEO",
  "SCORM_VIDEO": "SCORM_VIDEO"
};

const TaskType = {
  "VIDEO": "VIDEO",
  "ASSIGNMENT": "ASSIGNMENT",
  "FINAL_ASSIGNMENT": "FINAL_ASSIGNMENT"
};

const ReportSubmissionStatus = {
  "STARTED": "STARTED",
  "IN_REVIEW": "IN_REVIEW",
  "COMPLETED": "COMPLETED"
};

const CourseRegistrationActivityStatus = {
  "STARTED": "STARTED",
  "IN_REVIEW": "IN_REVIEW",
  "COMPLETED": "COMPLETED",
  "FAILED": "FAILED"
};

const { User, GroupMember, Group, Invitation, EnterpriseProfile, ReportResult, Report, Source, CourseRegistration, Course, ReportSubmission, TypeformResponse, CourseRegistrationActivity, AssignmentResponse, Notification, ModelUserConnection, ModelGroupConnection, ModelInvitationConnection, ModelEnterpriseProfileConnection, EnterpriseMembership, EnterpriseSubscription, SubscriptionEntitlement, SubscriptionItem, RbacMatrixConnection, RbacMatrix, RbacResource, ModelReportResultConnection, SkillScore, SubSkillScore, AssignmentTask, Skill, Link, SubSkill, ModelCourseRegistrationConnection, ModelCourseConnection, ResourceCollection, Resource, DocumentResource, VideoResource, CourseModule, Task, VideoTask, FinalAssignmentTask } = initSchema(schema);

export {
  User,
  GroupMember,
  Group,
  Invitation,
  EnterpriseProfile,
  ReportResult,
  Report,
  Source,
  CourseRegistration,
  Course,
  ReportSubmission,
  TypeformResponse,
  CourseRegistrationActivity,
  AssignmentResponse,
  Notification,
  ModelSortDirection,
  ModelAttributeTypes,
  Status,
  Role,
  InvitationStatus,
  EnterpriseMembershipStatus,
  SubscriptionProvider,
  EnterpriseSubscriptionStatus,
  FeatureSlug,
  FeatureType,
  SubscriptionItemType,
  BillingPeriodUnit,
  AssignmentSource,
  CourseRegistrationStatus,
  CourseType,
  CourseLearningStandard,
  CourseAccessType,
  CourseStatus,
  CourseUploadType,
  ResourceType,
  DocumentSource,
  VideoSource,
  TaskType,
  ReportSubmissionStatus,
  CourseRegistrationActivityStatus,
  ModelUserConnection,
  ModelGroupConnection,
  ModelInvitationConnection,
  ModelEnterpriseProfileConnection,
  EnterpriseMembership,
  EnterpriseSubscription,
  SubscriptionEntitlement,
  SubscriptionItem,
  RbacMatrixConnection,
  RbacMatrix,
  RbacResource,
  ModelReportResultConnection,
  SkillScore,
  SubSkillScore,
  AssignmentTask,
  Skill,
  Link,
  SubSkill,
  ModelCourseRegistrationConnection,
  ModelCourseConnection,
  ResourceCollection,
  Resource,
  DocumentResource,
  VideoResource,
  CourseModule,
  Task,
  VideoTask,
  FinalAssignmentTask
};