import dayjs from 'dayjs';
import { message } from 'antd';
import { v4 as uuidv4 } from 'uuid';
import { API, Auth } from 'aws-amplify';
import axios from 'axios';
import { CustomConfig } from '../backendConfigProvider';
import { Role, SubscriptionItemType } from '../models';

/**
 *
 *
 * @param {*} handlerFn
 * @return {*}
 */
const buttonize = (handlerFn) => ({
  role: 'button',
  onClick: handlerFn,
  onKeyDown: (event) => {
    // insert your preferred method for detecting the keypress
    if (event.keycode === 13) handlerFn(event);
  },
});

const toaster = (text, type = 'success', duration = 4) => {
  if (type === 'success') {
    message.success(text, duration);
  } else if (type === 'info') {
    message.info(text, duration);
  } else {
    message.error(text, duration);
  }
};

const getHeaders = async () => {
  const currentSession = await Auth.currentSession();
  const accessToken = currentSession?.getIdToken()?.getJwtToken();
  return {
    Authorization: `Bearer ${accessToken}`,
  };
};

const getAppVersion = () => CustomConfig.appVersion;

const postEvent = async (payload, path = '/events') => {
  try {
    const eventApiName = 'enterpriseappevents';
    const id = uuidv4();
    const headers = await getHeaders();
    await API.post(eventApiName, path, {
      headers,
      body: {
        ...payload,
        id,
        context: {
          app: {
            version: getAppVersion(),
          },
        },
      },
    });
  } catch (error) {
    console.log(error);
  }
};

const getUserCountry = async () => {
  try {
    const token = process.env.IpinfoToken;
    const api = `https://ipinfo.io/geo/?token=${token}`;
    const response = await axios.get(api);
    const countryCode = response?.data?.country?.toLowerCase();
    return countryCode;
  } catch (error) {
    return null;
  }
};

const isEligibleToViewAdminArea = (profileInfo) => {
  const eligibleRoles = [Role.OWNER, Role.ADMIN, Role.MANAGER];
  if (eligibleRoles.includes(profileInfo?.roles?.[0])) {
    return true;
  }
  return false;
};

/**
 *
 *
 */
const formatDate = (date) => dayjs(date).format('DD/MM/YYYY');

const getDateDifference = (targetDate, baseDate) => {
  const dateDifference = dayjs(targetDate).diff(baseDate, 'hours') / 24;

  return Math.ceil(dateDifference);
};

const getFirstAndLastName = (fullName) => {
  const arrayOfTexts = fullName.split(' ');
  const [firstName, ...lastNameArray] = arrayOfTexts;
  const lastName = lastNameArray.join(' ');
  return {
    firstName,
    lastName,
  };
};

const getCurrentPlanItem = (subscription) => subscription?.items?.find(
  (item) => item.itemType === SubscriptionItemType.PLAN,
);

const isOnFreeTrialPlan = (subscription) => {
  const currentPlanItem = getCurrentPlanItem(subscription);
  return Boolean(currentPlanItem?.itemPriceId.includes('free_'));
};

const isOnCustomPlan = (subscription) => {
  const currentPlanItem = getCurrentPlanItem(subscription);
  return Boolean(currentPlanItem?.itemPriceId.includes('custom_'));
};

const getPeriodText = (period, periodUnit, pluralize = true) => {
  if (!periodUnit) return null;
  const periodUnitText = `${periodUnit.charAt(0)}${periodUnit.substring(1).toLowerCase()}`;
  if (period > 1) {
    return `${period} ${periodUnitText}${pluralize ? 's' : ''}`;
  }
  return periodUnitText;
};

const getSubscriptionStatus = (subscription) => {
  const isFreeTrial = isOnFreeTrialPlan(subscription);

  if (isFreeTrial && (subscription?.status === 'NON_RENEWING')) {
    return 'In Trial';
  } if (isFreeTrial && (subscription?.status === 'CANCELLED')) {
    return 'Trial Expired';
  }

  const statusArray = [
    {
      status: 'ACTIVE',
      name: 'Active',
    },
    {
      status: 'NON_RENEWING',
      name: 'Non Renewing',
    },
    {
      status: 'CANCELLED',
      name: 'Expired',
    },
  ];

  const status = statusArray.find((item) => item.status === subscription?.status);

  if (status) {
    return status.name;
  }
  if (subscription?.status) {
    return subscription.status;
  }

  return '';
};

const hasInviteLimits = (subscription) => {
  const currentPlan = getCurrentPlanItem(subscription);
  // only enable limits when plan is present
  if (currentPlan && !isOnFreeTrialPlan(subscription)) {
    return true;
  }

  return false;
};

const isEligibleToHideCourse = (userRole = '') => [Role.ADMIN, Role.OWNER].includes(userRole);

const isBDMUser = (email = '') => {
  const bdmEmailRegex = new RegExp(/^admin\+[-a-zA-Z0-9]+@upskillist\.pro$/);
  return bdmEmailRegex.test(email);
};

const truncateText = (text, characterLimit = 15) => {
  if (!text) return '';
  if (text.length > 15) {
    return `${text.slice(0, characterLimit)}...`;
  }
  return text;
};

const isEligibleToManageBilling = (subscription, roles) => {
  if (!roles?.includes(Role.OWNER)) {
    // only owners allowed
    return false;
  }
  if (isOnCustomPlan(subscription)) {
    // cannot manage custom plans
    return false;
  }
  return true;
};

export {
  buttonize,
  formatDate,
  toaster,
  postEvent,
  getAppVersion,
  getHeaders,
  getUserCountry,
  isEligibleToViewAdminArea,
  getFirstAndLastName,
  getDateDifference,
  isOnFreeTrialPlan,
  isOnCustomPlan,
  getSubscriptionStatus,
  hasInviteLimits,
  getCurrentPlanItem,
  isEligibleToHideCourse,
  isBDMUser,
  truncateText,
  isEligibleToManageBilling,
  getPeriodText,
};
