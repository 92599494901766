import { DataStore, syncExpression } from 'aws-amplify';
import {
  AssignmentResponse, Course, CourseAccessType,
  CourseRegistration, CourseRegistrationActivity, User,
} from '../models';

let sub = 'INVALID_SUB';

DataStore.configure({
  syncExpressions: [
    syncExpression(User, () => (user) => user.sub('eq', sub)),
    syncExpression(CourseRegistration, () => (registration) => registration.userSub('eq', sub)),
    syncExpression(CourseRegistrationActivity, () => (activity) => activity.userSub('eq', sub)),
    syncExpression(AssignmentResponse, () => (response) => response.userSub('eq', sub)),
    syncExpression(Course, () => (response) => response.accessType('eq', CourseAccessType.PUBLIC)),
  ],
  syncPageSize: 100,
});

async function changeSync(passedSub: string, isLogin?: boolean): Promise<void> {
  if (sub !== passedSub) {
    sub = passedSub;

    if (isLogin) {
      await DataStore.clear();
    } else {
      await DataStore.stop();
    }
  }

  await DataStore.start();
}

export default changeSync;
